.logout {
    background: none;
    border: none;
    padding: 0;
    font: inherit;
    color: blue;
    text-decoration: underline;
    cursor: pointer;
    margin-top: 10px;
  }
  
.logout:hover {
    text-decoration: none;
}